import React from "react";
import { createUseStyles } from "react-jss";
import Pan from "../assets/pan.png";
import Text from "../assets/text.png";
const useStyles = createUseStyles({
  root: {
    width: "100%",
    height: 80,
    backgroundColor: "red",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },
  pan: {
    height: "20px"
  },
  text: {
    height: "20px"
  }
});

const Header = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.logoSection}>
        <img src={Text} alt="Discovereat" className={classes.text} />
        <img src={Pan} alt="Discovereat pan" className={classes.pan} />
      </div>
    </div>
  );
};

export default Header;
