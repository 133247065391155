import React from "react";
import Header from "../components/Header";
import ResetPassword from "../components/ResetPassword";
import queryString from "query-string";

const ResetPasswordWebsite = (props) => {
  const queryStringValues = queryString.parse(props.location.search);
  let token = queryStringValues.token;

  return (
    <div>
      <Header />
      <ResetPassword token={token} />
    </div>
  );
};

export default ResetPasswordWebsite;
