export default function customError(name, defaultMessage) {
  const rv = function(message) {
    this.name = name;
    this.message = message || defaultMessage || 'Custom Error';
    this.stack = new Error().stack;
  };

  rv.prototype = Object.create(Error.prototype);
  rv.prototype.constructor = rv;

  return rv;
}
