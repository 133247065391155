import React, { Component } from "react";

import { Switch, Route } from "react-router-dom";
import VerificationWebsite from "./pages/VerificationWebsite";
import ResetPasswordWebsite from "./pages/ResetPasswordWebsite";

import "./index.css";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/verifyPage" component={VerificationWebsite} />
        <Route exact path="/resetPassword" component={ResetPasswordWebsite} />
      </Switch>
    );
  }
}

export default App;
