import React from "react";
import Header from "../components/Header";
import Verification from "../components/Verification";
import queryString from "query-string";

const VerificationWebsite = (props) => {
  const queryStringValues = queryString.parse(props.location.search);
  let token = queryStringValues.token;

  return (
    <>
      <Header />
      <Verification token={token} />
    </>
  );
};

export default VerificationWebsite;
