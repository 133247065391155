import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { _verifyEmail } from "../api/api";

const useStyles = createUseStyles({
  root: {
    width: "100%",
    height: 350,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    height: "100%",
    width: "85%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const Verification = (props) => {
  // console.log("verification", props);
  const { token } = props;
  const [loading, setLoading] = useState(true);
  // const [response, setResponse] = useState("default");
  const classes = useStyles();

  useEffect(() => {
    verify();
  }, []);
  async function verify() {
    try {
      const res = await _verifyEmail(token);
      console.log(res);
    } catch (error) {
      console.log(error);
    }

    //setResponse(res);
    setLoading(false);
  }
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {loading ? (
          <h1>loading</h1>
        ) : (
          <h1 style={{ textAlign: "center", wdith: "80%" }}>
            Thank you for verifying your email!
          </h1>
        )}
      </div>
    </div>
  );
};

export default Verification;
