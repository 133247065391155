import customError from "../util/customError";

const ADDRESS = "https://api.discovereat.co.uk";

export async function get(path, body) {
  return await request(path, {
    headers: {},
    body,
  });
}

export const UnableToConnectException = customError(
  "UnableToConnectException",
  "Unable to connect to the server."
);
export const ServerErrorException = customError(
  "ServerErrorException",
  "There was an error on the server."
);
export const NotFoundException = customError(
  "NotFoundException",
  "Endpoint not found."
);
export const AuthenticationException = customError(
  "AuthenticationException",
  "User is not authenticated."
);
export const BadRequestException = customError(
  "BadRequestException",
  "Bad request sent."
);
export const TimeoutException = customError(
  "TimeoutException",
  "The request timed out."
);

export async function request(path, settings) {
  let response;
  try {
    response = await fetch(`${ADDRESS}${path}`, settings);
  } catch (e) {
    console.log("Unable to connect", e);
    throw new UnableToConnectException();
  }

  // if (response.status === 404 || response.status === 500 || response.status === 400 || response.status === 403) {
  //   trackError({
  //     path,
  //     settings,
  //     response
  //   });
  // }

  if (response.status === 404) throw new NotFoundException();
  if (response.status === 500) throw new ServerErrorException();
  if (response.status === 401) throw new AuthenticationException();
  if (response.status === 403) throw new AuthenticationException();
  if (response.status === 400)
    throw new BadRequestException(await response.json());

  return response;
}

export async function _verifyEmail(token) {
  try {
    const response = await get(`/profile/verify-email?token=${token}`);
    return response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function _ResetPassword(token, password) {
  try {
    const response = await get(
      `/auth/reset-password?token=${token}&newPassword=${password}`
    );
    return response.json();
  } catch (error) {
    console.log(error);
  }
}
