import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { _ResetPassword } from "../api/api";

import ActivityIndicator from "./activityIndicator/ActivityIndicator";

const useStyles = createUseStyles({
  root: {
    width: "100%",
    height: 100,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  mainSection: {
    width: "100%",
    paddingTop: 200,
    height: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    width: "90%",
    maxWidth: 600,
  },
  input: {
    width: "100%",
    margin: 10,
    border: "1px solid grey",
    marginBottom: 25,
    outline: "none !important",
    padding: "10px",
    fontSize: 20,
  },
  text: {
    width: "100%",
    marginLeft: 10,
    color: "grey",
    fontSize: 14,
  },
  button: {
    margin: 10,
    borderRadius: 23,
    width: "100%",
    height: 45,
    zIndex: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FF423D",
    color: "white",
    borderWidth: 0,
    cursor: "pointer",
  },
  title: {
    marginTop: 50,
  },
});

const ResetPassword = (props) => {
  const { token } = props;
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const classes = useStyles();

  async function Reset() {
    setLoading(true);
    setStatusMessage("");
    if (password === "") {
      setLoading(false);
      setStatusMessage("Please enter a password");
      return;
    }
    if (password === conPassword) {
      try {
        const res = await _ResetPassword(token, password);
        if (res.isSuccess) {
          console.log(res);
          setLoading(false);
          setStatusMessage("Your password has been successfully updated.");
        } else {
          throw new Error();
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        setStatusMessage(
          "An error occurred setting your password, your link may have expired."
        );
      }
    } else {
      setLoading(false);
      setStatusMessage("Passwords do not match");
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.mainSection}>
        <h1 className={classes.title}>Reset your password</h1>
        <form className={classes.form}>
          <div>
            <div className={classes.text}>New Password</div>
            <input
              className={classes.input}
              type="password"
              name="New Password"
              value={password}
              onChange={(change) => setPassword(change.target.value)}
            />
          </div>
          <div>
            <div className={classes.text}>Confirm Password</div>
            <input
              className={classes.input}
              type="password"
              name="Confirm Password"
              value={conPassword}
              onChange={(change) => setConPassword(change.target.value)}
            />
          </div>

          <div onClick={Reset} className={classes.button}>
            {loading ? <ActivityIndicator /> : "Reset"}
          </div>
          {/* <input  type="submit" value="Reset"  /> */}
          {statusMessage && <div>{statusMessage}</div>}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
